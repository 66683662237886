import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { doc, setDoc } from "@firebase/firestore"

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

function ChildModal(props) {
    const [name, setName] = useState("")
    const [numero, setNumero] = useState(0)
    const addLivreurFireBase = async () => {
        try {
            const livreurData = {
                name: name,
                numero: numero,
                status: false,
                work: []
            }
            await setDoc(doc(props.firebase_database, "livreurs", numero), livreurData)
            alert('Livreur ajouté avec success : ')
        } catch (error) {
            console.log(error);
            alert('Error dans addLivreurFireBase : ', error)
        }
    };

    return (
        <React.Fragment >
            <Box className="main">
                <TextField onChange={(event) => { setName(event.target.value) }} type="text" label="Nom" color="primary" sx={{ marginBottom: '5%' }} required />
                <TextField onChange={(event) => { setNumero(event.target.value) }} type="number" label="Numero" color="primary" sx={{ marginBottom: '5%' }} required />
                <Button variant="outlined" color="primary" onClick={addLivreurFireBase}>Ajouter</Button>
                <Modal
                    hideBackdrop
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <Box sx={{ ...style, width: 200 }}>
                        <h2 id="child-modal-title">LOL</h2>
                    </Box>
                </Modal>
            </Box>
        </React.Fragment>
    );
}

export default function NestedModal(props) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>

            <Button sx={{ marginTop: "5%" }} variant="outlined" size="large" onClick={handleOpen}>Ajouter Livreur</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style, width: 400 }}>
                    <h2 id="parent-modal-title">Ajouter Livreur</h2>
                    <ChildModal firebase_database={props.firebase_database} />
                </Box>
            </Modal>
        </div>
    );
}