import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { updateDoc, doc } from '@firebase/firestore';
import { useEffect } from 'react';


const OnlineOfflineSwith = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&:after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));

export default function ControlledSwitches(props) {
    const [checked, setChecked] = React.useState(true);

    const addLivreurWork = async (event) => {
        setChecked(event.target.checked);
        try {
            const newStatus = { status: event.target.checked }
            const livreurDoc = doc(props.firebase_database, "livreurs", props.numero)
            await updateDoc(livreurDoc, newStatus)
            alert('Status Changé avec success : ')
        } catch (error) {
            alert("Erreur dans le changement du status vous devez contactez le 0557950075 ")
        }
    };

    useEffect(() => {
        setChecked(props.status)
        // return () => {
        //   cleanup
        // }
    }, [])

    return (
        < FormControlLabel
            control={< OnlineOfflineSwith checked={checked}
                onChange={addLivreurWork} />}
            label="Online" />
    );
}