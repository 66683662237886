import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CallIcon from '@mui/icons-material/Call';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { doc, updateDoc } from "@firebase/firestore"

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

function ChildModal(props) {
    const [date, setDate] = useState(new Date())
    const [price, setPrice] = useState(0)
    const [restaurant, setRestaurant] = useState("")
    const addLivruerWork = async (previousWork) => {
        try {
            const newWork = { work: [...previousWork, { date: date, price: price, restaurant: restaurant, }] }
            const livreurDoc = doc(props.firebase_database, "livreurs", props.numero)
            await updateDoc(livreurDoc, newWork)
            alert('commande ajouté avec success : ')
        } catch (error) {
            alert("Erreur dans l'ajout d'une commande : ")
        }
    };

    return (
        <React.Fragment >
            <Box className="main">
                <TextField onChange={(event) => { setDate(event.target.value) }} type="date" label="date" color="primary" sx={{ marginBottom: '5%' }} required />
                <TextField onChange={(event) => { setPrice(event.target.value) }} type="number" label="prix" color="primary" sx={{ marginBottom: '5%' }} required />
                <TextField onChange={(event) => { setRestaurant(event.target.value) }} type="text" label="restaurant" color="primary" sx={{ marginBottom: '5%' }} required />
                <Button variant="outlined" color="primary" onClick={() => addLivruerWork(props.work)}>Ajouter</Button>
                <Modal
                    hideBackdrop
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <Box sx={{ ...style, width: 200 }}>
                        <h2 id="child-modal-title">LOL</h2>
                    </Box>
                </Modal>
            </Box>
        </React.Fragment>
    );
}

export default function NestedModal(props) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const openPhoneCall = (event) => {
        window.open('tel:' + event.target.value)
    }
    const clearWork = async () => {
        try {
            const newWork = { work: [] }
            const livreurDoc = doc(props.firebase_database, "livreurs", props.numero)
            await updateDoc(livreurDoc, newWork)
            alert('commande vidé avec success : ')
        } catch (error) {
            alert("Erreur dans le vidange des commande : ")
        }
    }
    return (
        <div>
            <Card sx={{ margin: "1%", display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} >
                <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant="h3" color="text.primary" >
                        {props.name}
                    </Typography>
                    {props.status &&
                        <Typography sx={{ marginTop: "2%" }} variant="h4" color="primary" >
                            Online
                        </Typography>
                    }
                    {!props.status &&
                        <Typography sx={{ marginTop: "2%" }} variant="h4" color="error" >
                            Offline
                        </Typography>
                    }
                    <Button onClick={openPhoneCall} startIcon={<CallIcon />} sx={{ marginTop: "2%" }} variant="outlined" size="large" value={props.numero} >{props.numero}</Button>
                    <Button onClick={handleOpen} startIcon={<AddCircleOutlineIcon />} sx={{ marginTop: "5%" }} variant="outlined" size="large" >Ajouter Commande</Button>
                    <Button color='error' sx={{ marginTop: "5%" }} variant="outlined" onClick={clearWork}>Total : {props.work.reduce((total, object) => parseInt(object.price) + total, 0)}</Button>
                </CardContent>
            </Card>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style, width: 400 }}>
                    <h2 id="parent-modal-title">Ajouter Commande</h2>
                    <ChildModal firebase_database={props.firebase_database} numero={props.numero} work={props.work} />
                </Box>
            </Modal>
        </div >
    );
}