
import { useState, useEffect } from "react"
import './App.css';
import firebase_database from "./firebase-config"
import { query, collection, getDocs, doc, getDoc, onSnapshot } from "@firebase/firestore"
import LivreurInfo from "./components/LivreurInfo"
import AddLivreur from './components/AddLivreur'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import SimpleLivreurOnlineOffline from './components/SimpleLivreurOnlineOffline'

const App = () => {
  const [livreurs, setLivreurs] = useState([])
  const [admin, setAdmin] = useState(false)
  const [simpleLivreur, setsimpleLivreur] = useState(false)
  const [simpleLivreurStatus, setSimpleLivreurStatus] = useState(false)
  const [phoneNumber, setphoneNumber] = useState("")
  const livreursFirebaseCollectionReference = collection(firebase_database, 'livreurs')

  useEffect(() => {
    const getLivreurs = async () => {
      const livreurs_list = await getDocs(livreursFirebaseCollectionReference)
      setLivreurs(livreurs_list.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      const q = query(collection(firebase_database, "livreurs"));
      const unsubscribe = onSnapshot(q, (livreurs_listSnapshot) => {
        setLivreurs(livreurs_listSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      });
    }
    // return () => {
    //   cleanup
    // }
    getLivreurs()
  }, [])

  const checkUser = async () => {
    if (phoneNumber == 1234) setAdmin(true)
    else {
      if (phoneNumber == '') {
        alert('vous devez remplire le champ du numero')
        return
      }
      const docRef = doc(firebase_database, "livreurs", phoneNumber);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setSimpleLivreurStatus(docSnap.data().status)

        setsimpleLivreur(phoneNumber)
        alert('vous etes bien connecté ' + docSnap.data().status)
      } else {
        // doc.data() will be undefined in this case
        alert('aucun utilisateur trouvé avec ce numero vous devez contacter 0557950075')
      }

    }
  }

  const livreurs_list_to_be_rendered = [].concat(livreurs)
    .sort((a, b) => b.status - a.status)
  return (
    <div className="main">
      {
        !admin && !simpleLivreur &&

        <div className="main">
          <TextField onChange={(event) => { setphoneNumber(event.target.value) }} type="number" label="numero" color="primary" sx={{ marginBottom: '5%' }} required />
          <Button onClick={checkUser} startIcon={<PlayCircleFilledWhiteIcon />} sx={{ marginTop: "2%" }} variant="outlined" size="large"  >Connexion</Button>
        </div>
      }
      {
        admin && !simpleLivreur &&
        <div className="main">
          {livreurs_list_to_be_rendered.map((livreur) => <LivreurInfo key={livreur.numero} name={livreur.name} status={livreur.status} numero={livreur.numero} work={livreur.work} firebase_database={firebase_database} />)}
          < AddLivreur firebase_database={firebase_database} />
        </div>
      }
      {
        !admin && simpleLivreur &&
        <SimpleLivreurOnlineOffline firebase_database={firebase_database} numero={simpleLivreur} status={simpleLivreurStatus} />
      }
    </div >
  );
}

export default App;
